var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"period-data",class:_vm.periodDataClass},[_c('div',{staticClass:"header",class:{
        'header--first': _vm.isFirst,
        'header--last': _vm.isLast,
        'header--is-league': _vm.isLeague,
        'is-total': _vm.isTotal,
      }},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"body",class:{
        'body--first': _vm.isFirst,
        'body--last': _vm.isLast,
        'body--is-league': _vm.isLeague,
        'is-total': _vm.isTotal,
      }},[_vm._v(" "+_vm._s(_vm._f("toFixed2")(Number.isNaN(_vm.data) ? 0 : _vm.data))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }