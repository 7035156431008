<template>
  <div class="period-data" :class="periodDataClass">
    <div
      class="header"
      :class="{
        'header--first': isFirst,
        'header--last': isLast,
        'header--is-league': isLeague,
        'is-total': isTotal,
      }"
    >
      {{ title }}
    </div>
    <div
      class="body"
      :class="{
        'body--first': isFirst,
        'body--last': isLast,
        'body--is-league': isLeague,
        'is-total': isTotal,
      }"
    >
      {{ Number.isNaN(data) ? 0 : data | toFixed2 }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'GamePeriodData',
  props: {
    title: {
      type: String,
      default: '',
    },
    data: {
      type: [String, Number],
      default: 0,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    isLeague: {
      type: Boolean,
      default: false,
    },
    isTotal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    periodDataClass() {
      return [this.isMobile ? 'period-data--mobile' : 'period-data--desktop'];
    },
  },
};
</script>

<style scoped lang="scss">
$borderRadiusDesktop: 4px;
$borderRadiusMobile: 8px;

.period-data {
  font-family: Roboto-Regular, sans-serif;
  display: flex;
  flex-direction: column;

  &--desktop {
    width: 6.81rem;

    & .header {
      padding: 0.2rem 0;
      font-size: 0.75rem;
      color: white;
      background-color: #403b46;

      &--first {
        border-radius: $borderRadiusDesktop 0 0 0;
      }

      &--last {
        border-radius: 0 $borderRadiusDesktop 0 0;
        font-weight: 900;
        text-transform: uppercase;
      }
    }

    & .body {
      font-family: Roboto-Black, sans-serif;
      padding-bottom: 0.2rem;
      font-size: 1.875rem;
      font-weight: 800;
      color: #132839;
      background-color: #f4ffd8;

      &--first {
        border-radius: 0 0 0 $borderRadiusDesktop;
      }

      &--last {
        border-radius: 0 0 $borderRadiusDesktop 0;
        background-color: #e5fca6;
      }
    }
  }

  &--mobile {
    width: 6.625rem;
    border-radius: 8px;

    & .header {
      padding: 0.2rem 0;
      font-size: 0.75rem;
      color: white;
      background-color: #132839;
      border-radius: $borderRadiusMobile $borderRadiusMobile 0 0;

      &--is-league {
        color: #132839;
        background-color: #cbee6b;
        font-weight: 900;
        text-transform: uppercase;

        &.header.is-total {
          font-size: 0.875rem;
          text-transform: uppercase;
        }
      }

      &.is-total {
        font-weight: bold;
        text-transform: capitalize;
      }
    }

    & .body {
      font-size: 1.875rem;
      font-weight: 800;
      line-height: 1.67;
      color: #132839;
      background-color: #f4ffd8;
      border-radius: 0 0 $borderRadiusMobile $borderRadiusMobile;

      &--is-league {
        background-color: #cbee6b;

        &.body.is-total {
          padding: 0;
          font-size: 2.25rem;
          line-height: 1.39;
        }
      }
    }

    @media screen and (max-width: 360px) {
      width: 5.8rem;
    }

    @media screen and (max-width: 330px) {
      width: 4.8rem;
      height: 100%;

      & .header {
        white-space: pre-wrap;
      }

      & .body {
        font-size: 1.2rem;
        height: 100%;

        &--is-league {
          padding-top: 1rem;
          &.body.is-total {
            font-size: 1.65rem;
            line-height: 1.39;
          }
        }
      }
    }
  }
}
</style>
